import React, { useEffect, useState } from 'react'
import SlotCounter from 'react-slot-counter';
import light2fast from '../../images/newGifts/light2fast.gif'
import slotMachineBg from '../../images/newGifts/slot-machine-bg.png'
import winnerRevel from '../../images/newGifts/winner_revel.gif'
import light_animation_fast_new from '../../images/newGifts/SLOT_machine_light.gif'


const FlipCard = ({val,ref,wnnr_acc}) => {
    const [flipped, setflipped] = useState(false)
    useEffect(() => {  
     let t = setTimeout(() =>{
        setflipped(true)
     },10000)
     //clearTimeout(t)
    }, [])

    
    
  return (
    <div className="countdownItem">
        <div className={`flipFard ${flipped? 'is-flipped':''}`} >
            <div className="card__face card__face--front">
                <div className="countdownItem">
                    <div className="coundDownContent">
                        {/* <audio src={rflbg} id='rflaud' autoPlay={false} loop controls={false}></audio> */}
                        <SlotCounter containerClassName="raffleCounter" charClassName="raffleCounterItem"  startValue={'548247'} value={val} ref={ref}  startValueOnce duration={8} speed={1} dummyCharacterCount={50} />
                        <img src={slotMachineBg} className="slotMacBg" alt='imag'/>
                    </div>
                    {/* <video src={light_animation_fast_new} autoPlay loop muted></video> */}
                    <img src={light_animation_fast_new} alt='vid'/>
                </div>
            </div>
            <div className="card__face card__face--back">
                <div className="winnerAccountDetails">
                    <h6>{wnnr_acc?.acc_name}</h6>                    
                    <p className='stateN' >{wnnr_acc?.acc_state?.state_name}</p>
                    <p className='stateName'>{wnnr_acc?.acc_email}</p>
                    {/* <p className='accid'>{wnnr_acc?.acc_unique_code}</p> */}
                </div>
                {/* <video src={winnerRevel} autoPlay loop muted></video> */}
                <img src={winnerRevel} alt="vid" />
            </div>
        </div>
    </div>
  )
}

export default FlipCard
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

const DoughnutChart = ({labelset, dataset, lbltxt, width, height}) => {
    const chartdata = {
        labels: labelset,
        datasets: [{
            type: 'doughnut',
            label: lbltxt,
            data: dataset,
            backgroundColor: ['rgba(102, 153, 255, 0.3)'],
            borderColor: ['rgb(102, 153, 255)'],
            borderWidth: 1
        }]
    }
    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: true,
                backgroundColor: '#8c8cd9',
                titleColor: '#22225e',
                titleFont: {
                    size: '13%',
                    weight: '900'
                },
                bodyColor: '#4040bf'
            },
            filler: {
                propagate: true
            }
        },
        scales: {
            y: {
                ticks: {
                    min: 0,
                    stepSize: 10
                }
            }
        },
        layout: {
            padding: 0
        }
    }

  return (
    <>
        <Doughnut data={chartdata} options={options} width={width} height={height} />
    </>
  )
}

export default DoughnutChart
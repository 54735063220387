import { useState, useEffect } from 'react'
import '../../../styles/Chart.css'
import axios from 'axios'
import Server from '../../../Server'
import { Chart, BarElement, BarController, CategoryScale, LinearScale, LineElement, PointElement, RadialLinearScale, ArcElement, Legend, Tooltip, scales, Filler } from 'chart.js'
import BarChart from '../charts/BarChart'
import LineChart from '../charts/LineChart'
import PieChart from '../charts/PieChart'
import DoughnutChart from '../charts/DoughnutChart'
import RadarChart from '../charts/RadarChart'
import PolarareaChart from '../charts/PolarareaChart'

Chart.register(
    BarElement, 
    BarController,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    ArcElement,
    Legend,
    Tooltip,
    scales,
    Filler
)

const WnnrSttWise = () => {
    const [wnnr, setWnnr] = useState()
    const [stt, setStt] = useState()
    const [wnnrstt, setWnnrstt] = useState()

    const getwnnrstt = async () => {
        try {
            const res1 = await axios.get(`${Server}/winner/fetch/flgone`)
            const res2 = await axios.get(`${Server}/statem/view`)
            const dta1 = await res1.data
            const dta2 = await res2.data
            // console.log(dta1.data);
            // console.log(dta2.data);

            if ((dta1.statuscode === 220)&&(dta2.statuscode === 220)) {
                setWnnr(dta1.data)
                    const sttarr = dta2.data.map(elm => elm.state_name)
                    console.log(sttarr);
                    const wnnrcnt = sttarr.map((elm, i) => {
                        let cnt = 0
                        dta1.data.forEach((el, j) => {
                            if (String(el.wnnr_acc.acc_state.state_name)===String(elm)) {
                                cnt++
                            }
                        })
                        return cnt
                    })
                    console.log(wnnrcnt);
                    if ((sttarr.length > 0)&&(sttarr.length === wnnrcnt.length)) {
                        setStt(sttarr)
                        setWnnrstt(wnnrcnt)
                    }
                    else {
                        setStt(null)
                        setWnnrstt(null)
                    }
            }
            else {
                setWnnr(null)
            }
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        getwnnrstt()
    }, [])

    const labelset = stt?.map(elm => elm)
    const dataset = wnnrstt?.map(elm => elm)
    const lbltxt = 'Winners'

  return (
    <>
        {
            (wnnr&&wnnr.length>0) ? (
                <div className='chartfnc'>
                    <div className="hds">State-wise No. of Winners</div>
                    <div className="btngrp">
                        <div className="hd">Select Chart Type</div>
                        <div className="btns">
                            <button className='btn btn-sm btn-outline-info' onClick={(e) => {
                                e.preventDefault()
                                document.querySelector('#barch').style.display='flex'
                                document.querySelector('#linech').style.display='none'
                                document.querySelector('#piech').style.display='none'
                                document.querySelector('#dougch').style.display='none'
                                document.querySelector('#radch').style.display='none'
                                document.querySelector('#polach').style.display='none'
                            }}>Bar Chart</button>
                            <button className='btn btn-sm btn-outline-info' onClick={(e) => {
                                e.preventDefault()
                                document.querySelector('#barch').style.display='none'
                                document.querySelector('#linech').style.display='flex'
                                document.querySelector('#piech').style.display='none'
                                document.querySelector('#dougch').style.display='none'
                                document.querySelector('#radch').style.display='none'
                                document.querySelector('#polach').style.display='none'
                            }}>Line Chart</button>
                            <button className='btn btn-sm btn-outline-info' onClick={(e) => {
                                e.preventDefault()
                                document.querySelector('#barch').style.display='none'
                                document.querySelector('#linech').style.display='none'
                                document.querySelector('#piech').style.display='flex'
                                document.querySelector('#dougch').style.display='none'
                                document.querySelector('#radch').style.display='none'
                                document.querySelector('#polach').style.display='none'
                            }}>Pie Chart</button>
                            <button className='btn btn-sm btn-outline-info' onClick={(e) => {
                                e.preventDefault()
                                document.querySelector('#barch').style.display='none'
                                document.querySelector('#linech').style.display='none'
                                document.querySelector('#piech').style.display='none'
                                document.querySelector('#dougch').style.display='flex'
                                document.querySelector('#radch').style.display='none'
                                document.querySelector('#polach').style.display='none'
                            }}>Doughnut Chart</button>
                            <button className='btn btn-sm btn-outline-info' onClick={(e) => {
                                e.preventDefault()
                                document.querySelector('#barch').style.display='none'
                                document.querySelector('#linech').style.display='none'
                                document.querySelector('#piech').style.display='none'
                                document.querySelector('#dougch').style.display='none'
                                document.querySelector('#radch').style.display='flex'
                                document.querySelector('#polach').style.display='none'
                            }}>Radar Chart</button>
                            <button className='btn btn-sm btn-outline-info' onClick={(e) => {
                                e.preventDefault()
                                document.querySelector('#barch').style.display='none'
                                document.querySelector('#linech').style.display='none'
                                document.querySelector('#piech').style.display='none'
                                document.querySelector('#dougch').style.display='none'
                                document.querySelector('#radch').style.display='none'
                                document.querySelector('#polach').style.display='flex'
                            }}>Polar Area Chart</button>
                        </div>
                    </div>
                    <div className="dtlscharts">
                        <div className='chrtgrp' id="barch" style={{display:'flex'}}>
                            <BarChart labelset={labelset} dataset={dataset} lbltxt={lbltxt} />
                        </div>
                        <div className='chrtgrp' id="linech" style={{display:'none'}}>
                            <LineChart labelset={labelset} dataset={dataset} lbltxt={lbltxt} />
                        </div>
                        <div className='chrtgrp' id="piech" style={{display:'none'}}>
                            <PieChart labelset={labelset} dataset={dataset} lbltxt={lbltxt} />
                        </div>
                        <div className='chrtgrp' id="dougch" style={{display:'none'}}>
                            <DoughnutChart labelset={labelset} dataset={dataset} lbltxt={lbltxt} />
                        </div>
                        <div className='chrtgrp' id="radch" style={{display:'none'}}>
                            <RadarChart labelset={labelset} dataset={dataset} lbltxt={lbltxt} />
                        </div>
                        <div className='chrtgrp' id="polach" style={{display:'none'}}>
                            <PolarareaChart labelset={labelset} dataset={dataset} lbltxt={lbltxt} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="chartfnc">
                    <span>Relevant data doesn't Exist</span>
                </div>
            )
        }
    </>
  )
}

export default WnnrSttWise
import React from 'react'
import { PolarArea } from 'react-chartjs-2'

const PolarareaChart = ({labelset, dataset, lbltxt, width, height}) => {
    const chartdata = {
        labels: labelset,
        datasets: [{
            type: 'polarArea',
            label: lbltxt,
            data: dataset,
            backgroundColor: ['rgba(102, 153, 255, 0.3)'],
            borderColor: ['rgb(102, 153, 255)'],
            borderWidth: 1
        }]
    }
    const options = {
        responsive: false,
        plugins: {
            tooltip: {
                enabled: true,
                backgroundColor: '#8c8cd9',
                titleColor: '#22225e',
                titleFont: {
                    size: '13%',
                    weight: '900'
                },
                bodyColor: '#4040bf'
            },
            filler: {
                propagate: true
            }
        },
        scales: {
            y: {
                ticks: {
                    min: 0,
                    stepSize: 10
                }
            },
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 50,
                suggestedMax: 50
            }
        },
        layout: {
            padding: 0
        }
    }

  return (
    <>
        <PolarArea data={chartdata} options={options} width={1000} height={500} />
    </>
  )
}

export default PolarareaChart
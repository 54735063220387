import React, { useState, useEffect, useRef } from 'react';
import '../../styles/newRaffle.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios'

import thankYouVideo from '../../images/newGifts/thank_you.mp4'
import ldrwhdr from '../../images/lckydrwimg/SEL_tiger_light.mp4'
import bgaud from '../../audios/Sounds/Bgsound.mp3'
import startBtn from '../../images/lckydrwimg/Button01.png'

import closeBtn from '../../images/newGifts/Button02.png'
// import wnnrflp from '../../audios/Sounds/winner_Reveal_aud.mp3'

import bgvideo from '../../images/newGifts/bgvideo.mp4'
import GiftListsRight from './GiftListsRight';

import Server from '../../Server';
import { useParams } from 'react-router-dom';
import RaffleGitPopup from './RaffleGitPopup';
import SliderBanner from './SliderBanner';
import LuckyDrawCounters from './LuckyDrawCounters';
import Clock from './Clock';
import LeftWinners from './LeftWinners';

const RaffleNew = () => {
  
    
    const [showLuckyDrawCounters, setshowLuckyDrawCounters] = useState(false);
    const [flagZeroData, setFlagZeroData] = useState([]);
    const [loading, setloading] = useState(false);
    const [playloading, setPlayloading] = useState(false);
    const [flagOneLoading, setflagOneLoading] = useState(false);   
    const [winners, setwinners] = useState([]);   
    const [allGifts, setallGifts] = useState();
    const [showRaffleGitPopup, setshowRaffleGitPopup] = useState(false);
    const [userSelectedGift, setuserSelectedGift] = useState();
    const [isFlipped, setisFlipped] = useState(false);
    const [giftBalance, setgiftBalance] = useState();
    const [congPopup, setcongPopup] = useState(false);

    useEffect(() => {
        // if (document.querySelector('#bgaud')) {
        //     document.querySelector('#bgaud').autoplay = true
        //     document.querySelector('#bgaud').load()
        // }
        // document.body.addEventListener('click', (e) =>{
        //     if (document.querySelector('#bgaud')) {
        //         document.querySelector('#bgaud').autoplay = false
        //         document.querySelector('#bgaud').load()
        //     }
        // })
        // setTimeout(()=>{           
        //     if (document.querySelector('#bgaud')) {               
        //         document.querySelector('#bgaud').autoplay = true
        //         document.querySelector('#bgaud').load()
        //     }
        // },4000)
        
    }, [])

    const bgaudfn = () => {
        if (document.querySelector('#bgaud')) {
            document.querySelector('#bgaud').autoplay = true
            document.querySelector('#bgaud').load()
        }
    }


    const getWinnersData = async () => {
        setflagOneLoading(true)
        if (document.querySelector('#bgaud')) {
            document.querySelector('#bgaud').autoplay = true
            document.querySelector('#bgaud').load()
        }
        try {
            const res = await axios.get(`${Server}/winner/fetch/flgone/`);
            
            if(res.status == 200){
                if(res.data.data.length > 0){ 
                    let p = {} 
                    res.data.data.map(item=>{                        
                        if(p[item.assigned_gift.prz_itm._id]){
                            let v = p[item.assigned_gift.prz_itm._id] + 1;
                            p[item.assigned_gift.prz_itm._id] = v
                        }else{
                            p[item.assigned_gift.prz_itm._id] = 1
                        }
                    })
                    setgiftBalance(p)
                    setwinners(res.data.data)             
                }
                setflagOneLoading(false)                
            }else{
                setflagOneLoading(false)
            }
           
        } catch (error) {
            console.error(error)
        }
    }


    const getGifts = async () => {
        setloading(true)
        try {
            const res = await axios.get(`${Server}/prize/view/`);
            
            if(res.status == 200){
                if(res.data.data.length > 0){  
                   
                    let tempArr = []
                    res.data.data.map(item=>{
                       tempArr.push(item)
                    });    
                                  
                    // Using reduce() to remove duplicates by 'id'
                    const uniqueData = tempArr.reduce((acc, current) => {                        
                        const x = acc.find(item => item.prz_itm?._id === current?.prz_itm?._id);
                        if (!x) {
                        acc.push(current);
                        }
                        return acc;
                    }, []);   
                      
                    uniqueData.map((item)=>{
                        let temQty = 0;                        
                            tempArr.map(item2=>{
                                if(item.prz_itm._id == item2.prz_itm._id){
                                    temQty = temQty + item2.prz_qty;
                                }
                            });
                        item.qty = temQty                          
                        
                    })           
                   setallGifts(uniqueData)                    
                }
                setloading(false)                
            }else{
                setloading(false)
            }
           
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getGifts();
        getWinnersData();
    }, []);


  
    const handleShowStartPopup = ()=>{
        bgaudfn()
        setshowRaffleGitPopup(!showRaffleGitPopup)
    }
    const handleSelectGift = (gift)=>{
        if (document.querySelector('#bgaud')) {
            document.querySelector('#bgaud').autoplay = false
            document.querySelector('#bgaud').load()
        }       
        setuserSelectedGift(gift);    
        
    }
  

    useEffect(() => {
      if(userSelectedGift){       
        getFlagWithZero(userSelectedGift?.prz_itm?._id)
        
        // Popup thank you here ====
        // setTimeout(()=>{
        //     setcongPopup(true)
        // },30000)
      }
    }, [userSelectedGift])

    const getFlagWithZero = async(giftID)=>{

        setPlayloading(true)
        try {
            const res = await axios.get(`${Server}/winner/fetchbygft/flgzero/${giftID}`);
            if(res.status == 200){
                if(res.data.data.length > 0){   
                    //console.log(res.data.data)                   
                   setFlagZeroData(res.data.data)     
                   setshowLuckyDrawCounters(true)
                }else{
                    alert("Currently we are not available!")
                }
                setPlayloading(false)                
            }else{
                setPlayloading(false)
            }
        } catch (error) {
            console.error(error)
        }
    }



    const handleBackHome = ()=>{
        setshowRaffleGitPopup(false)
        setisFlipped(false)
        setshowLuckyDrawCounters(false)
        setuserSelectedGift(null);
    }
  
    return (
        
        <section id="newRaffleWrapper">
             <audio src={bgaud} id="bgaud" autoPlay={true} loop controls={false}></audio>
            {congPopup && <div className="thankyoupopup">      
                <button className="closeCong" onClick={()=> setcongPopup(false)} >
                    <img src={closeBtn} alt='imag'/>
                </button>          
                <video src={thankYouVideo} autoPlay loop muted className='thankYouVideo'/>
            </div>}
            <video src={bgvideo} autoPlay loop muted className='bgVideo' />
            <div className="raffleContainer">
                <LeftWinners winners={winners} flagOneLoading={flagOneLoading} />
                <div className="raffleCenter">
                    <div className="raffleHeader">                        
                        <div className="selTigerVideo">
                            <video src={ldrwhdr} autoPlay loop muted></video>
                        </div>
                    </div>
                    <div className={`raffleMeta scene ${showRaffleGitPopup ?'rafflePopupShow' : ''} ${showLuckyDrawCounters ?'luckyDrawCountersPopup' : ''}`}>
                        {playloading && <div className="LoadingDice"> <p>Please wait</p><img src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt='imag'/></div>}
                         {!playloading && showLuckyDrawCounters &&<LuckyDrawCounters getWinnersData={getWinnersData} userSelectedGift={userSelectedGift} flagZeroData={flagZeroData} handleBackHome={handleBackHome}/>}
                        <div className={`flipFard ${isFlipped? 'is-flipped':''}`}>
                            <div className="card__face card__face--front">
                                {showRaffleGitPopup && <RaffleGitPopup giftBalance={giftBalance} loading={loading} allGifts={allGifts} handleSelectGift={handleSelectGift} handleShowStartPopup={handleShowStartPopup}/>
                                }
                                <div className="BannerContainer">
                                    <SliderBanner/>
                                </div>
                                <div className="startRaffleBtnSec">
                                    <button id="startRaffleBtn" onClick={handleShowStartPopup}>
                                        <img src={startBtn} alt='imag'/>
                                    </button>
                                </div>
                            </div>
                            {/* Front part end */}
                            <div className="card__face card__face--back">
                               
                            </div>
                        </div>
                       
                    </div>
                    <div className="raffleFooter"></div>
                </div>
                <GiftListsRight giftItems={allGifts} loading={loading}/>
            </div>
        </section>
    ) 
}

export default RaffleNew
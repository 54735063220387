import { useState, useEffect, useRef } from 'react'
import { NavLink, useParams, useNavigate } from 'react-router-dom'
import { FaUserPlus, FaCloudUploadAlt, FaUserEdit, FaUserMinus } from 'react-icons/fa'
import { MdCasino, MdSpaceDashboard , MdGroupRemove } from 'react-icons/md'
import { PiGameControllerFill } from "react-icons/pi";
import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from 'axios'
import Server from '../Server'
// import Utils from '../data/Utils.js'

const Home = () => {
  const [cntst, setCntst] = useState()
  const counterRef = useRef(null)
  const navig = useNavigate()

  const vwData = async () => {
    try {
      const res = await axios.get(`${Server}/contest/view`)
      const dta = await res.data.data
      // console.log(dta)
      setCntst(dta)
    } catch (error) {
      console.error(error)
    }
  }
  const tcktstat = async () => {
    try {
      const res = await axios.get(`${Server}/ticket/view`)
      const dta = await res.data
      const bookd = dta.data.filter((elm) => {
        return (String(elm.tckt_status)==='Booked')
      })
      console.log(bookd.length);
      const won = dta.data.filter((elm) => {
        return (String(elm.tckt_status)==='Won')
      })
      console.log(won.length);
      const blcked = dta.data.filter((elm) => {
        return (String(elm.tckt_status)==='Blocked')
      })
      console.log(blcked.length);
    } catch (error) {
      console.error(error)
    }
  }
  // const checkcntsttckt = async () => {
  //   const id = '66eb2385abb6e4626971fc55'
  //   try {
  //     const res = await axios.get(`${Server}/ticket/view/chckcntst/`+id)
  //     const data = await res.data.data
  //     console.log(data)
  //     // 10019912
  //     // 10019913
  //   } catch (error) {
  //     console.error(error)
  //   }
  // }
  useEffect(() => {
    vwData()
    tcktstat()
  }, [])

  const runallcntst = async (e) => {
    let cflg = 1
    e.preventDefault()

    try {
      if (window.confirm(`Confirm to run all Contests ?`)) {
        const res = await axios.get(`${Server}/contest/autorun`)
        const dta = await res.data
        console.log(dta.message);
        // for (let i=0; i<cntst.length; i++,cflg++) {
        //   const res = await axios.get(`${Server}/contest/autorun/`+cntst[i]._id)
        //   const dta = await res.data
        //   console.log(dta.message);
        // }
        if (cflg === cntst.length) {
          const res = await axios.get(`${Server}/contest/autoscreenset`)
          const dta = await res.data
          console.log(dta);
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const runautorafl = async (e) => {
    e.preventDefault()
    try {
      const res = await axios.get(`${Server}/contest/autoscreenset`)
      const dta = await res.data
      console.log(dta);
      if (dta.statuscode === 220) {
        window.alert(dta.message)
        vwData()
      }
      else {
        window.alert(dta.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updtallwnnrflgzero = async (e) => {
    e.preventDefault()
    // const {wnnrflag}
    try {
      const res = await axios.post(`${Server}/winner/edit/flagall`, {wnnrflag:0})
      const dta = await res.data
      console.log(dta)
      if (dta.statuscode === 220) {
        window.alert(dta.message)
        vwData()
      }
      else {
        window.alert(dta.message)
        vwData()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const hndlresetall = async (e) => {
    e.preventDefault()

    try {
      if (window.confirm(`Confirm to Reset all Contest data?`)) {
        const res = await axios.get(`${Server}/contest/edit/resetall`)
        const dta = await res.data
        console.log(dta)
        if (dta.statuscode === 220) {
          window.alert(dta.message)
          vwData()
        }
        else {
          window.alert(dta.error)
          vwData()
        }
      }
      else {
        vwData()
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
        <div className="wbpg">
          <Header/>

          <div className="main container">
            <div className="hdng">Home</div>
            <div className="dtlsec">
              <div className="inf"><span>Here all the Contest details are listed by folllowing table.</span></div>
              <div className="btnsec"><span>Start Autorun All Contests : </span><NavLink className='navlnk'><button className='btn btn-sm btn-info' onClick={runallcntst}>Start All Contest</button></NavLink></div>
              <div className="btnsec"><span>Set Winner Screens & Run Counters : </span><NavLink className='navlnk'><button className='btn btn-sm btn-light' onClick={runautorafl}>Set Screens</button></NavLink></div>
              <div className="btnsec"><span>Start Game Raffle : </span><NavLink className='navlnk' to={`/admin/raffle/auto`}><button className='rnbtn'><PiGameControllerFill/></button></NavLink></div>
              <div className="btnsec"><span>Reset Winner Flags : </span><NavLink className='navlnk'><button className='rmvbtn' onClick={updtallwnnrflgzero}><MdGroupRemove/></button></NavLink></div>
              <div className="btnsec"><span>Reset All Winners : </span><NavLink className='navlnk'><button className='rmvbtn' onClick={hndlresetall}><MdGroupRemove/></button></NavLink></div>
              <div className="btnsec"><span>Check Dashboards : </span><NavLink className='navlnk' to={`/admin/dashboard`}><button className='dashbtn'><MdSpaceDashboard/></button></NavLink></div>
            </div>
          </div>

          <Footer/>
        </div>
    </>
  )
}

export default Home
import React from 'react'
import bike from '../../images/newGifts/BIKE.png'
import bmw from '../../images/newGifts/bmw.png'
import galaxyTab from '../../images/newGifts/GALAXY-TAB.png'
import honda from '../../images/newGifts/HONDA.png'
import tv from '../../images/newGifts/LED-TV.png'
import startBtn from '../../images/lckydrwimg/Button01.png'

import closeBtn from '../../images/newGifts/Button02.png'

const RaffleGitPopup = ({loading,giftBalance,allGifts,handleSelectGift,handleShowStartPopup}) => {
  console.log(giftBalance)
  return (
    <div className="raffleGitPopup">
        <div className="raffleGitPopupContent">
        {loading && <div className="loader">Loading... <img src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" alt='imag'/></div>}
            <ul>
                {!loading && allGifts && allGifts.length > 0 && allGifts.map((item,index)=>{
                    return(
                        <li key={"ag"+item._id}  className='pipupGiftItem'>
                            <div className="giftImage">
                                {item?.prz_itm?.gft_name.toLowerCase().indexOf('bmw') >= 0? <img src={bmw} alt='imag'/>:null}
                                {item?.prz_itm?.gft_name.toLowerCase().indexOf('bike') >= 0? <img src={bike} alt='imag'/>:null}
                                {item?.prz_itm?.gft_name.toLowerCase().indexOf('tablet') >= 0? <img src={galaxyTab} alt='imag'/>:null}
                                {item?.prz_itm?.gft_name.toLowerCase().indexOf('hyundai') >= 0? <img src={honda} alt='imag'/>:null}
                                {item?.prz_itm?.gft_name.toLowerCase().indexOf('television') >= 0? <img src={tv} alt='imag'/>:null}
                            </div>
                            <h5>{item?.prz_itm?.gft_name}</h5>   
                            <div className="balanceSelect">
                                {giftBalance && giftBalance[item?.prz_itm?._id] ? <p><b>Balance: </b>{item?.qty - giftBalance[item?.prz_itm?._id]}</p>:<p><b>Balance: </b>{item?.qty}</p> }
                                
                                {giftBalance && giftBalance[item?.prz_itm?._id] && (item?.qty - giftBalance[item?.prz_itm?._id] > 0) ? <button className='selectGiftPopup' onClick={()=>handleSelectGift(item)}>
                                    <img src={startBtn} alt='imag'/>
                                </button> : null}
                                
                               {item?.qty > 0 && (!giftBalance || !giftBalance[item?.prz_itm?._id]) &&<button className='selectGiftPopup' onClick={()=>handleSelectGift(item)}>
                                    <img src={startBtn} alt='imag'/>
                                </button> }
                            </div>                                            
                        </li>
                    )
                })}
            </ul>
        </div>
        <div className="raffleGitPopupFooter">
            <button className="closePopup" onClick={handleShowStartPopup}>
                <img src={closeBtn} alt='imag'/>
            </button>
        </div>
    </div>
  )
}

export default RaffleGitPopup